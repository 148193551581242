import {
  GetImageCaptcha,
  SendPhoneCaptcha,
} from "@/api/user"

// 验证码相关mixin
export default {
  data() {
    return {
      captcha_id: "",
      captcha_data: "",
      captcha_flag: true,
      send_loading: false,
      captchaNumber: 0,
      ruleForm: {
        phone: "",
        captcha_value: "",
        captcha: "",
      },
      captchaInterval: null,
    }
  },
  methods: {
    // 发送验证码到手机
    async SendPhoneCaptcha(is_login) {
      // 倒计时大于0
      if (this.captchaNumber) return;

      const validResult = this.ruleFormValid({
        phone: this.ruleForm.phone,
        captcha_value: this.ruleForm.captcha_value,
      })
      // 表单校验结果: false
      if (!validResult) return;

      this.send_loading = true
      await SendPhoneCaptcha({
        ...this.ruleForm,
        captcha_id: this.captcha_id,
        captcha: null,
        is_login, // 1 用于登录，0 其他（注册）
        type: 'login'
      }).then((res) => {
        // 判断
        this.send_loading = false
        if (res.data && res.data.allowRegistry === 1) {
          this.$message.warning(res.msg)
          // 切换到注册面板
          this.$emit("toggleTab", "registry")
          return
        } else if (res.data && res.data.allowRegistry === 0) {
          this.$message.warning("该号码尚未注册")
          return
        }

        // 成功后，开启60秒倒计时
        this.captchaNumber = 60
        this.captchaInterval = setInterval(() => {
          if (this.captchaNumber === 1) {
            clearInterval(this.captchaInterval)
          }

          this.captchaNumber--
        }, 1000)

        this.$message.success("验证码已发送，注意查收")
      })
        .catch(() => {
          this.send_loading = false
          this.GetImageCaptcha()
        })
    },
    // 表单校验
    ruleFormValid(ruleForm) {
      // 校验手机号
      if (ruleForm.phone !== undefined) {
        if (!ruleForm.phone.trim() || !/^1\d{10}$/.test(ruleForm.phone)) {
          this.$message.warning("请填写正确的手机号")
          return false
        }
      }

      // 校验用户名
      if (ruleForm.username !== undefined) {
        if (!ruleForm.username.trim()) {
          this.$message.warning("用户名不能为空")
          return false
        }
      }

      // 校验密码
      if (ruleForm.password !== undefined) {
        if (!ruleForm.password.trim()) {
          this.$message.warning("密码不能为空")
          return false
        }
      }

      // 校验图形验证码
      if (ruleForm.captcha_value !== undefined) {
        if (!ruleForm.captcha_value.trim()) {
          this.$message.warning("图形验证码不能为空")
          return false
        }
      }

      // 校验短信验证码
      if (ruleForm.captcha !== undefined) {
        if (!ruleForm.captcha.trim()) {
          this.$message.warning("短信验证码不能为空")
          return false
        }
      }

      return true
    },
    // 获取图形验证码
    async GetImageCaptcha() {
      if (this.captcha_flag) {
        this.captcha_flag = false
        const res = await GetImageCaptcha()

        this.captcha_data = res.data.captcha_data
        this.captcha_id = res.data.captcha_id
        this.captcha_flag = true
      }
    }
  },
  mounted() {
    this.GetImageCaptcha()
    document.addEventListener("visibilitychange", this.handleVisibilityChange)
  },
  beforeDestroy() {
    clearInterval(this.captchaInterval)
    this.captchaInterval = null

    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    )
  },
}
