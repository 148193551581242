<template>
  <div class="sub-page-frame flex" style="align-items: flex-start" v-if="false">
    <div class="aside-bar relative">
      <div
        class="aside-item"
        v-for="(el, i) in currentAside"
        :key="i"
        @click="routerJump(el)"
        :class="{ active: el.to.indexOf(currentRouter) > -1 }"
      >
        <span>{{ el.text }}</span>
        <span class="number_mark" v-if="el.number">{{ el.number }}</span>
      </div>

      <!-- <div class="quit flex center">
        <div class="quit-icon mb">
          <img src="./../assets/icons/quit.svg" />
        </div>
        <div class="version">Version 1.0.1</div>
      </div> -->
    </div>
  </div>

  <div
    class="test-container w-full h-[calc(100vh-60px)] flex !items-start pl-3 pr-5"
    v-else
  >
    <div class="aside-bar relative">
      <div
        class="aside-item"
        v-for="(el, i) in currentAside"
        :key="i"
        @click="routerJump(el)"
        :class="{ active: el.to.indexOf(currentRouter) > -1 }"
      >
        <span>{{ el.text }}</span>
        <span class="number_mark" v-if="el.number">{{ el.number }}</span>
      </div>

      <!-- <div class="quit flex center">
        <div class="quit-icon mb">
          <img src="./../assets/icons/quit.svg" />
        </div>
        <div class="version">Version 1.0.1</div>
      </div> -->
    </div>
    <div class="content h-full">
      <div class="flex h-[44px]">
        <div class="flex">
          <img src="./../assets/frame/notice.svg" />
          <div class="ml small-desc">公告：{{ notice }}</div>
        </div>

        <slot name="notice-right"></slot>
      </div>

      <slot></slot>
      <template v-if="noRouter !== true">
        <div class="scale-box flex !justify-center w-full h-[calc(100%-74px)]">
          <div
            class="router-view w-full h-full overflow-auto"
            :class="{
              'hide-scrollbar': currentRouter === '/my_record/my_record_index',
            }"
          >
            <router-view />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import notices from "@/api/notice";

export default {
  props: ["aside", "noRouter"],
  data() {
    return {
      notice: "",
      online_number: 0,
    };
  },
  computed: {
    currentRouter() {
      return this.$route.path;
    },
    currentAside() {
      return (this.aside && this.aside.filter((el) => el.show !== false)) || [];
    },
  },
  methods: {
    routerJump({ to, noClick }) {
      if (to !== this.$route.path && !noClick) {
        this.$router.push(to.constructor === Array ? to[0] : to);
      }
    },
    async ListNotice() {
      const res = await notices.ListNotice({ page_num: 1, page_size: 1 });

      this.notice =
        (res.data.noticeList[0] && res.data.noticeList[0].notice_title) ||
        "暂无";
    },
  },
  created() {
    this.ListNotice();
  },
};
</script>

<style lang="less" scoped>
@bottom-half-height: calc(100vh - 90px);
@aside-bar-width: 130px;

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.number_mark {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #dc3545;
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
}

.sub-page-frame {
  padding: 15px;
}
.aside-bar {
  width: @aside-bar-width;
  height: @bottom-half-height;
  background-color: #1f2437;
  border-radius: 12px;
  padding: 25px 10px;
  margin-top: 15px;
  margin-right: 15px;

  .aside-item {
    height: 38px;
    line-height: 38px;
    border-radius: 12px;
    margin-bottom: 15px;
    font-size: 13px;
    text-align: center;
    color: #8a90a0;
    transition: 0.4s;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: rgba(255, 255, 255, 0.02);
    }

    &.active {
      background-color: #2c3143;
      color: #fff;
    }
  }
}
.content {
  width: calc(100% - (@aside-bar-width + 15px));
}

.quit {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  align-content: center;
  flex-wrap: wrap;

  .version {
    width: 100%;
    text-align: center;
  }
}
.version {
  color: #8a90a0;
  font-size: 12px;
}
</style>
