import { MessageBox } from 'element-ui'

export const Confirm = (message, callback, catchCallback) => {
  MessageBox.confirm(message, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => callback && callback())
    .catch(() => {
      catchCallback && catchCallback()
    })
}

export const NewConfirm = (message, { confirmText = '确定', cancelText = '取消', html = false } = {}) => {
  return MessageBox.confirm(message, '提示', {
    confirmButtonText: confirmText,
    cancelButtonText: cancelText,
    type: 'warning',
    dangerouslyUseHTMLString: html,
  })
}
