<template>
  <div class="easy_page">
    <div class="preview">
      <v-md-editor class="md-preview" :value="content" mode="preview"></v-md-editor>
    </div>
  </div>
</template>

<script>
import instance from '@/utils/request'
import subPageFrame from '@/components/subPageFrame.vue'
import Vue from 'vue'
import VMdEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import VuePressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import Prism from 'prismjs'

VMdEditor.use(VuePressTheme, {
  Prism,
})
Vue.use(VMdEditor)

export default {
  components: { subPageFrame },
  data() {
    return {
      content: '',
      previewDialog: '',
    }
  },
  methods: {
    async ListRichText() {
      const res = await instance({
        method: 'get',
        url: '/ListRichText?position=平台规则',
      })
      this.content = res.data[0].content
    },
  },
  created() {
    this.ListRichText()
  },
}
</script>

<style scoped>
.preview {
  height: 100%;
  border-radius: 8px;
  overflow: auto;
}
</style>
