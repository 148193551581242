<template>
  <div class="comp confirm-prompt">
    <slot name="custom-button" :open="promptContent">
      <el-button @click="promptContent">
        <!-- 确认提示按钮 -->
        <slot></slot>
      </el-button>
    </slot>

    <my-dialog :state="dialog" @close="dialog = false" title="确认提示" width="80vw">
      <div class="flex center mb2">{{ title }}</div>
      <v-md-editor
        class="md-preview"
        :value="content"
        mode="preview"
      ></v-md-editor>

      <div
        class="flex center mt"
        style="margin-bottom: 30px"
        v-if="confirmCheckbox"
      >
        <input
          type="checkbox"
          style="width: 17px; height: 17px"
          v-model="checkboxStatus"
        />
        <span class="ml">我已认真阅读，理解并同意</span>
      </div>
      <div class="flex center mt">
        <el-button @click="confirm_">确定</el-button>
        <el-button @click="dialog = false">取消</el-button>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import instance from "@/utils/request";
import Vue from "vue";
import VMdEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import VuePressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import Prism from "prismjs";
import myDialog from "./myDialog.vue";

VMdEditor.use(VuePressTheme, {
  Prism,
});
Vue.use(VMdEditor);

export default {
  components: { myDialog },
  props: ["prompt", "confirmCheckbox", "confirmBeforeCall"],
  data() {
    return {
      title: "",
      content: "",
      dialog: false,
      checkboxStatus: false,
    };
  },
  methods: {
    async promptContent() {
      await this.confirmBeforeCall && this.confirmBeforeCall()

      const { data } = await instance.get(
        `/ListRichText?position=${this.prompt || "xxx"}`
      );
      this.content = data[0].content;
      this.title = data[0].title;
      this.dialog = true;
    },
    confirm_() {
      if (!this.confirmCheckbox || (this.confirmCheckbox && this.checkboxStatus)) {
        this.dialog = false;
        this.$emit("confirmCall");
        return;
      }
      this.$message.warning("请认真阅读并勾选同意后继续")
    },
  },
};
</script>

<style></style>
